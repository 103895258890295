<template>
  <div>
    <ul class="documentos">
      <li class="title text-right">Documentos</li>
      <template v-if="ticket.documentos.length > 0">
      <li v-for="documento in ticket.documentos" :key="documento.id">
        <a href="#" @click.prevent="descargar_documento(documento)">{{ documento.nombre_original }}</a>
      </li>
      </template>
      <li v-else>No hay documentos</li>
    </ul>

    <div v-if="ticket.etapa_actual != etapa_no_viable" class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" @click="$refs.uploadfile.click()">Cargar documento</button>
        <input type="file" name="uploadfile" id="uploadfile" ref="uploadfile" @change="cargar_documento">
      </div>
    </div>
  </div>
</template>

<script>
  import apiTicket from '@/apps/tickets/api/tickets'
  export default {
    props: {
      ticket: {
        type: Object,
        default() {
          return {
            id: null,
            etapa_actual: 0,
            documentos: []
          }
        }
      }
      ,etapa_no_viable: 0
    }
    ,methods: {
      async cargar_documento() {
        if (this.$refs.uploadfile.files.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un documento','error','alert');

        let payload = new FormData();
        payload.append('documentos[0]', this.$refs.uploadfile.files[0]);

        let res = (await apiTicket.agregar_documento(this.ticket.id, payload)).data;
        this.$log.info('res', res);

        this.$refs.uploadfile.value = null;
        this.$emit('update');
      }
      ,async descargar_documento(documento) {
        try {
          this.$log.info('documento', documento);
          let res = (await apiTicket.descargar_documento(this.ticket.id, documento.id)).data;
          this.$helper.descargar_documento(res, documento.nombre_original);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;

    .title {
      background-color: #4c4c4c !important;
      color: #fff;
      font-size: 1.4em;
      padding: 5px 10px !important;
    }

    li {
      background-color: rgb(230, 230, 230);

      span.subtitle {
        background-color: rgb(206, 206, 206);
        width: 160px;
        font-weight: bold;
        padding: 5px 10px;
        display: inline-block;
      }
    }
  }

  .documentos {
    li {
      padding: 5px 10px !important;
      margin-bottom: 1px;
    }

    li:last-child {
      margin: 0px;
    }
  }

  input[type="file"] {
    display: none;
  }
</style>
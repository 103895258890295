<template>
  <div>
    <Modal :options="{width: '80vw',close:true,esc:false}" @close="$emit('close')">
      <div class="title">Ticket: {{ ticket.ticket }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-12" v-html="$helper.nl2br(ticket.descripcion)"></div>
            </div>

            <hr>

            <Comentarios :ticket="ticket" :etapa_no_viable="etapa_no_valida" @update="actualizar_ticket" />
          </div>
          <div class="col-sm-4">
            <ul class="detalles">
              <li class="title text-right">Información</li>
              <li><span class="subtitle">Asignación:</span> <span v-if="ticket.usuario_asignado_nombre"> {{ ticket.usuario_asignado_nombre }}</span><button v-else class="btn btn-primary btn-sm mr-2" @click="asignar_ticket">Asignarse</button></li>
              <li><span class="subtitle">Fecha asignación:</span> {{ ticket.asignacion_at ? $moment(ticket.asignacion_at).format(datetime_format) : 'N/A' }}</li>
              <li><span class="subtitle">Fecha creación:</span> {{ $moment(ticket.created_at).format(datetime_format) }}</li>
              <li><span class="subtitle">Usuario creador:</span> {{ ticket.nombre }}</li>
              <li><span class="subtitle">Etapa actual:</span> {{ ticket.etapas && ticket.etapas.length > 0 ? ult_etapa_activa(ticket).etapa.etapa : 'N/A' }}</li>
            </ul>

            <Documento :ticket="ticket" :etapa_no_viable="etapa_no_valida" @update="actualizar_ticket" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <template v-if="ticket.usuario_asignado_nombre && ult_etapa_activa(ticket).posicion != etapa_no_valida">
            <button v-if="ult_etapa_activa(ticket).posicion > 1" class="btn btn-secondary" @click="modal_retroceder=true">Retroceder etapa</button>
            <button v-if="ult_etapa_activa(ticket).posicion < ultima_etapa" class="btn btn-success ml-2" @click="avanzar_etapa">Avanzar etapa</button>
            <button v-if="ult_etapa_activa(ticket).posicion != etapa_no_valida" class="btn btn-danger ml-2" @click="modal_no_viable=true">No viable</button>
            </template>
            <template v-if="$auth.can('tickets','usuario_administrador')">
            <button class="btn btn-secondary ml-2" @click="modal_sla=true">Revisar SLA</button>
            <button v-if="$auth.can('tickets','re-asignar')" class="btn btn-info ml-2" @click="modal_reasignar=true">Asignar / Re-asignar</button>
            </template>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal_reasignar" :options="{width: '40vw', close: true, type: 'secondary'}" @close="modal_reasignar=false">
      <div class="title">Re-asignar ticket</div>
      <div class="body">
        <div class="row form-group">
          <label for="area_usuarios" class="col-form-label col-sm-3">Usuario</label>
          <div class="col-sm-9">
            <select v-model="reasignar_usuario" name="area_usuarios" id="area_usuarios" class="form-control">
              <option value="na">Seleccionar usuario</option>
              <option v-for="usuario in area.usuarios" :value="usuario.id">{{ usuario.nombre }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary" @click="reasignar_ticket">Re-asignar</button>
            <button class="btn btn-danger ml-2" @click="modal_reasignar=false">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal_retroceder" :options="{width: '40vw',type:'danger',close:true,esc:false}" @close="cancelar_retroceder">
      <form @submit.prevent="retorceder_etapa">
        <div class="title">Retroceder etapa</div>
        <div class="body">
            <div class="row form-group">
              <label for="razon_rechazo" class="col-form-label col-sm-3">Razón para retroceder</label>
              <div class="col-sm-9"><textarea v-model="razon_retroceso_etapa" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control"></textarea></div>
            </div>
          </div>
          <div class="footer">
            <div class="row">
              <div class="col-sm-12 text-right">
                <button class="btn btn-primary mr-2">Retroceder etapa</button>
                <button type="button" class="btn btn-danger" @click="cancelar_retroceder">Cancelar</button>
              </div>
            </div>
          </div>
      </form>
    </Modal>

    <Modal v-if="modal_no_viable" :options="{width: '40vw',type:'danger',close:true,esc:false}" @close="cancelar_retroceder">
      <form @submit.prevent="marcar_no_viable">
        <div class="title">Marcar como "No viable"</div>
        <div class="body">
            <div class="row form-group">
              <label for="razon_no_viable" class="col-form-label col-sm-3">Razón para marcar "No viable"</label>
              <div class="col-sm-9"><textarea v-model="razon_no_viable" name="razon_no_viable" id="razon_no_viable" cols="30" rows="10" class="form-control"></textarea></div>
            </div>
          </div>
          <div class="footer">
            <div class="row">
              <div class="col-sm-12 text-right">
                <button class="btn btn-primary mr-2">Marcar como "No viable"</button>
                <button type="button" class="btn btn-danger" @click="cancelar_retroceder">Cancelar</button>
              </div>
            </div>
          </div>
      </form>
    </Modal>

    <Sla v-if="modal_sla" :ticket="ticket" @close="modal_sla=false" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  
  import Comentarios from './Detalle/Comentario'
  import Documento from './Detalle/Documento'
  import Sla from './Detalle/Sla'

  import apiTicket from '@/apps/tickets/api/tickets'

  export default {
    components: {
      Modal, Comentarios, Documento, Sla
    }
    ,props: {
      ticket_id: {
        type: Number
        ,default: 0
      }
      ,area: {
        type: Object
        ,default() {
          return {
            aplicaciones: []
            ,etapas: []
            ,usuarios: []
          }
        }
      }
    }
    ,data() {
      return {
        ticket: {
            id: null,
            area_id: null,
            requerimiento_id: null,
            usuario_id: null,
            usuario_asignacion_id: null,
            aplicacion_id: null,
            nombre: null,
            prioridad: null,
            detalles: null,
            asignacion_at: null,
            resuelto_at: null,
            etapa: null,
            estatus: null,
            created_at: null,
            updated_at: null,
            asignacion: null,
            comentarios: [],
            documentos: [],
            etapas: []
        }
        ,datetime_format: 'DD MMM YYYY HH:mm'
        ,razon_retroceso_etapa: null
        ,razon_no_viable: null
        ,reasignar_usuario: 0
        ,modal_reasignar: false
        ,modal_retroceder: false
        ,modal_no_viable: false
        ,modal_sla: false
      }
    }
    ,mounted() {
      this.obtener_ticket();
    }
    ,methods: {
      async obtener_ticket() {
        try {
          this.ticket = (await apiTicket.obtener_ticket(this.ticket_id)).data;
        }catch(e) {
          this.$log.error('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,requerimiento(id) {
        let requerimiento = null;

        this.dependencias.requerimientos.forEach(req => {
          if (req.id == id)
          requerimiento = req;
        })

        return requerimiento ? requerimiento.nombre : 'N/A';
      },
      async asignar_ticket() {
        try {
          let res = (await apiTicket.asignar_ticket(this.ticket_id)).data;
          this.$log.info('res', res);

          this.obtener_ticket();
          this.$emit('update');
        }catch(e) {
            this.$log.error('error',e);
            this.$helper.showAxiosError(e,'Error');
        }
      },
      actualizar_ticket() {
        this.obtener_ticket();
        this.$emit('update');
      }
      ,async avanzar_etapa() {
        try {
          let res = (await apiTicket.avanzar_etapa(this.ticket.id)).data;
          this.$log.info('res', res);

          this.actualizar_ticket();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async retorceder_etapa() {
        try {
          if (!this.razon_retroceso_etapa || this.razon_retroceso_etapa == '')
            return this.$helper.showMessage('Error','No se a definido la razón de rechazo','error','alert');

          let res = (await apiTicket.retroceder_etapa(this.ticket.id, {razon_rechazo: this.razon_retroceso_etapa})).data;
          this.$log.info('res', res);

          this.cancelar_retroceder();
          this.actualizar_ticket();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async marcar_no_viable() {
        try {
          if (!this.razon_no_viable || this.razon_no_viable == '')
            return this.$helper.showMessage('Error','No se a definido la razón del rechazo','error','alert');

          let res = (await apiTicket.no_viable(this.ticket.id, {razon_no_valido: this.razon_no_viable})).data;
          this.$log.info('res', res);

          this.cancelar_retroceder();
          this.actualizar_ticket();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cancelar_retroceder() {
        this.modal_retroceder=false;
        this.razon_retroceso_etapa = null;

        this.modal_no_viable = false;
        this.razon_no_viable = null;
      }
      ,ult_etapa_activa(ticket) {
        let etapas = [];

        ticket.etapas.forEach(etapa => {
          if (etapa.estatus == 1)
            etapas.push(etapa)
        });

        if (etapas.length == 0)
          etapas.push(ticket.etapas[0]);

        etapas.sort((a,b) => {
          return a.posicion < b.posicion ? -1 : 1;
        })

        this.$log.info('etapas: ',etapas);

        return etapas[0];
      }
      ,async reasignar_ticket() {
        try {
          let res = (await apiTicket.reasignar_ticket(this.ticket.id, this.reasignar_usuario)).data;
          this.$log.info('res',res);

          this.$emit('update');
          this.obtener_ticket();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    },
    computed: {
      ultima_etapa() {
        let ultima_etapa = null;

        let etapas = this.$helper.clone(this.area.etapas);
        etapas.sort((a, b) => {
          if (a.posicion < b.posicion) return -1;
          if (a.posicion > b.posicion) return 1;

          return 0;
        });

        etapas.forEach(etapa => {
          if (ultima_etapa === null && etapa.tipo == 1)
            ultima_etapa = etapa.posicion;
        })

        if (ultima_etapa === null)
          ultima_etapa = 1;

        return ultima_etapa;
      }
      ,etapa_no_valida() {
        let etapa_no_valida = null;

        let etapas = this.$helper.clone(this.area.etapas);
        etapas.sort((a, b) => {
          if (a.posicion < b.posicion) return -1;
          if (a.posicion > b.posicion) return 1;

          return 0;
        });

        etapas.forEach(etapa => {
          if (etapa_no_valida === null && etapa.tipo == 2)
            etapa_no_valida = etapa.posicion;
        })

        return etapa_no_valida;
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;

    .title {
      background-color: #4c4c4c !important;
      padding: 5px 10px !important;
    }

    li {
      background-color: rgb(230, 230, 230);

      span.subtitle {
        background-color: rgb(206, 206, 206);
        width: 160px;
        font-weight: bold;
        padding: 5px 10px;
        display: inline-block;
      }
    }
  }

  .detalles {
    margin-bottom: 20px;
  }
</style>